<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Media</h3>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <label for="type">Media Type <span class="text-red">*</span></label>
                <b-form-radio-group
                  v-model="type"
                  @change="typeChange"
                >
                  <b-form-radio value="Image">
                    Image
                  </b-form-radio>
                  <b-form-radio value="Video">
                    Video
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-row>
                <b-col sm="12">
                  <div 
                    v-if="fileUrl === ''"
                    id="dropzone"
                    class="flex flex-col preview-image dashed"
                    :class="loading ? 'pointer-none' : ''"
                  >
                    <div
                      class="flex flex-col items-center"
                      @click="$refs.file.$refs.input.click()"
                    >
                      <feather-icon
                        icon="UploadCloudIcon"
                        size="30"
                      />
                      <span class="font-18px">Select your image</span>
                      <span>Recommened dimension : 1440x740 px</span>
                      <span>Image: Max 300 MB (PNG, JPG, MP4)</span>
                    </div>
                  </div>
                  <b-img
                    v-if="type === 'Image' && fileUrl !== ''"
                    :src="fileUrl"
                    class="image-full cursor-pointer"
                    @click="$refs.file.$refs.input.click()"
                  />
                  <video
                    v-if="type === 'Video' && fileUrl !== ''"
                    width="320"
                    controls
                  >
                    <source :src="fileUrl">
                  </video>
                </b-col>
                <b-col sm="12" class="mt-12px">
                  <b-form-group>
                    <label for="banner-image">Image Upload <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      ref="image"
                      name="image"
                      rules="required"
                    >
                      <b-form-file
                        ref="file"
                        v-model="file"
                        :selectedFile="file"
                        :disabled="loading"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose file"
                        accept=".jpg, .png, .mp4"
                        @input="inputImageRenderer"
                        :class="loading ? 'loading-form-file': ''"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="banner-link">Link Banner</label>
                    <b-form-input
                      v-model="link"
                      placeholder="Link"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Content</h3>
            </b-col>
            <b-col sm="12">
              <b-tabs pills>
                <b-tab title="English" active>
                  <b-form-group>
                    <label for="content-banner-name">Banner Name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="banner-name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="content.english.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Banner name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="content.english.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Thai">
                  <b-form-group>
                    <label for="content-banner-name">Banner Name</label>
                    <b-form-input
                      v-model="content.thai.name"
                      placeholder="Banner name"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="content.thai.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Chinese">
                  <b-form-group>
                    <label for="content-banner-name">Banner Name</label>
                    <b-form-input
                      v-model="content.chinese.name"
                      placeholder="Banner name"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="content.chinese.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Status</h3>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <label for="status">Status <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <v-select
                    v-model="status"
                    :state="errors.length > 0 ? false : null"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <div class="fixed-footer justify-content-end">
      <b-button
        variant="outline-secondary"
        :to="{ name: 'home-banner-list'}"
      >
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        :disabled="loading"
        @click.prevent="validationForm"
      >
        <span>Add Banner</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Dropzone from 'dropzone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormRadioGroup, BFormRadio, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTab, BTabs, BFormTextarea } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import bannerStoreModule from '../bannerStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
      link: '',
      file: null,
      fileUrl: '',
      type: 'Image',
      status: 'Activate',
      content: {
        thai: {
          name: '',
          note: '',
        },
        english: {
          name: '',
          note: '',
        },
        chinese: {
          name: '',
          note: '',
        },
      },
      avoidRouteLeave: false,
    }
  },
  methods: {
    typeChange() {
      this.file = null
      this.fileUrl = ''
    },
    async inputImageRenderer(file) {
      if (!file) return;
      if ((this.file.size / 1024 / 1024) > 300) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append('files', file);

      this.loading = true;
      const response = await store.dispatch('app-banner/uploadImage', form);
      const { data, status } = response.data;
      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error uploading file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }

      this.fileUrl = data[0];
      this.loading = false;
    },
    async validationForm() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) return
      if ((this.file.size / 1024 / 1024) > 300) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const banner = await store.dispatch('app-banner/addBanner', {
        name: this.content.english.name,
        url: this.fileUrl,
        link: this.link,
        status: this.status,
        type: this.type,
        content: this.content
      })

      const { status: createStatus } = banner.data
      if (createStatus !== 200) {
        this.makeToast(false)
        return;
      }

      this.avoidRouteLeave = true
      this.$router
        .push({ name: 'home-banner-list' })
        .then(() => {
          this.makeToast(true)
        })
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Create succeeded!' : 'Create failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `You has been created “${this.content.english.name}” Banner.` : 'Can not create banner.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  beforeRouteLeave(_, from, next) {
    if (this.avoidRouteLeave) {
      next()
      return
    }

    this.$swal({
      title: "Are you sure to leave?",
      text: "Do you really want to leave? you have unsaved changes!",
      icon: "warning",
      iconColor: "#FF9F43",
      showCancelButton: true,
      confirmButtonText: "Leave",
      customClass: {
        htmlContainer: "my-50",
        actions: "flex-row-reverse my-1",
        confirmButton: "btn btn-danger px-5",
        cancelButton: "btn btn-outline-secondary px-5 mr-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (!result.value) {
        next(false);
      } else {
        next()
      }
    });
  },
  mounted() {
    const dropzoneElement = document.getElementById('dropzone')
    if (dropzoneElement) {
      const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.file.setFiles([file])
      })
    }
  },
  setup() {
    const taost = useToast()
    const BANNER_APP_STORE_MODULE_NAME = 'app-banner'
    const statusOptions = [
      { label: 'Activate', value: 'Activate' },
      { label: 'Inactivate', value: 'Inactivate' },
    ]

    // Register module
    if (!store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.registerModule(BANNER_APP_STORE_MODULE_NAME, bannerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.unregisterModule(BANNER_APP_STORE_MODULE_NAME)
    })

    return {
      taost,
      statusOptions,
    }
  },
}
</script>